/* Variables, Naming may vary from project to project */

/* ==========================================================================
   Fonts
   ========================================================================== */

$font-stack-default: 'Arial', 'Helvetica', sans-serif;


/* ==========================================================================
   Colors
   ========================================================================== */

/* text */
$text-color: #12182d;
$text-highlight-color: #f1a948;
$text-color-light: #fff;
$text-color-light-border: rgba(255,255,255,0.3);


/* background */
$bg-content: #fff;
$bg-light: #fff;
$bg-medium: #F5F3F3; /* grey */
$bg-dark: #282622; /* dark grey */
$bg-highlight: #f1a948; /* orange */


$bg-btn: #f1a948;

/* line-colors */
$border-light: #fff;
$border-dark: #12182d; /* dark blue */
$border-highlight: #f1a948; /* orange */

$line-basic: #ccc;


/* ==========================================================================
   Custom Properties
   ========================================================================== */

:root {
    --text-color: #12182d;
    --text-highlight-color: #f1a948;
    --spacing-l: 4em;
}

.c-bg-dark {
    --text-color: #fff;
}

.c-bg-highlight {
    --text-highlight-color: #fff;
}

@media only screen and (min-width:1200px) {
    --spacing-l: 6em;
}

@media only screen and (max-width: 600px) {
    --spacing-l: 3em;
}