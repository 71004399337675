
/* ==========================================================================
   Default settings defined as variables
   ========================================================================== */


/* -------------------------------------------------------------------------
 Base font sizes (used by scut, sass-mq)
 --------------------------------------------------------------------------- */

$defaultPxSizeValue: 16;
$defaultPxSize: 16px;

/* -------------------------------------------------------------------------
 Fontstack definitions
 --------------------------------------------------------------------------- */

$fontstack: 'Arial', 'Helvetica', sans-serif;
//$seriffontstack: Georgia, 'Big Caslon', 'Book Antiqua', 'Palatino Linotype', serif;


/* -------------------------------------------------------------------------
 Wordpress: Theme name (same as 'project name' in 'gulp config')
 --------------------------------------------------------------------------- */

$themename: werosoft;
