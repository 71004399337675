
/* ==========================================================================
   Default color definitions defined as sass list ( used via color mixin)
   ========================================================================== */

$color: (

	/* ----------------------------------------------------------------------
	 CICD Colors
	 ------------------------------------------------------------------------ */

	CIColor: 							#1e2154,
	CIColorRgba: 						rgba(30,33,84,1),

	CIColordimmed:						#e6e5f1,

	accentCIColor: 						#e94f4f,


	/* ----------------------------------------------------------------------
	 Heading colors
	 ------------------------------------------------------------------------ */

	siteTitle: 							#ffffff,


	/* ----------------------------------------------------------------------
	 Background colors
	 ------------------------------------------------------------------------ */

	languageNavigationBackgroundColor: 	#f1f1f1,
	brightBackgroundColor: 				#ffffff,
	neutralImageBackEnd: 				#c2c2c2,


	darkBackgroundColor: 			#111111,
	darkBackgroundColorRgba: 		rgba(17,17,17,.1),

	baseBackground: 				#ffffff,
	imageBackground: 				#e6e6e6,


	/* ----------------------------------------------------------------------
	 Text colors
	 ------------------------------------------------------------------------ */

	brightTextColor: 				#ffffff,
	darkTextColor: 					#5E5E5E,
	errorTextColor: 				#d80c0c,
	footerNavigationTextColor: 		#5E5E5E,
	quoteColor: 					#837EBB,


	/* ----------------------------------------------------------------------
	 Link colors
	 ------------------------------------------------------------------------ */

	contentTextColorvisited: 		#111111,
	contentTextHoverColor: 			#111111,


	/* ----------------------------------------------------------------------
	 Border colors
	 ------------------------------------------------------------------------ */

	languageNavigationSeparator: 	#ffffff,
	AccordeonToggleBorder:			#D5D5D5,
	infoGrafikIndexBorder: 			#B3B2B2,
	scrollNavBorderColor: 			#efefef,


	formInputBorderColor: 			#a8a09a,
	formInputBorderColorfocus: 		#1b365e,
	formInputBorderErrorColor: 		#d80c0c,

	/* ----------------------------------------------------------------------
	 Shadow colors
	 ------------------------------------------------------------------------ */

	scrollNavShadowColor: 			#dfdfdf,

);
