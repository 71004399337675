
/* ==========================================================================
   Config
   ========================================================================== */

// General configurations
@import 'common/settings/config';

// Color definitions
@import 'common/settings/colors';

@import "common/su/_variables";
@import "common/su/_normalize";
@import "common/su/_mixins";
@import "common/su/_helpers";


/* ==========================================================================
   Saas Tools (imported from node_modules)
   ========================================================================== */

// Setting for Saas Tool 'Scut: Sass-CSS Utitilies'
@import 'common/settings/scut';

// Scut: Sass-CSS Utitilies (https://davidtheclark.github.io/scut/)
@import 'scut/dist/scut';


// Setting for Saas Tool 'Media Queries with superpowers'
@import 'common/settings/breakpoints';

// Media Queries with superpowers (https://github.com/sass-mq/sass-mq)
@import 'sass-mq/mq';


// Susy is a lightweight grid-layout engine for Sass (http://oddbird.net/susy/)
//@import 'susy/sass/susy';


/* ==========================================================================
   Sizes
   ========================================================================== */


@import 'common/settings/sizes/font-sizes';
@import 'common/settings/sizes/image-sizes';
@import 'common/settings/sizes/height-sizes';
@import 'common/settings/sizes/padding-sizes';
@import 'common/settings/sizes/width-sizes';


/* ==========================================================================
   Helper
   ========================================================================== */

@import 'helper/center-things';
@import 'helper/clearfix';
@import 'helper/colors';
@import 'helper/hide-things';
@import 'helper/pseudo-elements';
@import 'helper/wordbreak';
@import 'helper/wrappers';


/* ==========================================================================
   Normalize, Boilerplate
   ========================================================================== */
/*
@import 'common/consistency/normalize';
@import 'common/consistency/boilerplate';
@import 'common/consistency/sys';
*/

/* ==========================================================================
   Common
   ========================================================================== */

@import 'common/fonts/fontface';
@import 'common/fonts/webfont';

//@import 'common/base';
//@import 'common/container';
@import 'common/error-messages';
@import 'common/typography';


/* ==========================================================================
   Elements
   ========================================================================== */

//@import 'elements/a';
//@import 'elements/buttons';
//@import 'elements/downloads';
@import 'elements/images';
//@import 'elements/main';
//@import 'elements/p';
//@import 'elements/unordered-lists';

/* ==========================================================================
   Components
   ========================================================================== */

/* -------------------------------------------------------------------------
 Siteheader
 --------------------------------------------------------------------------- */

@import 'components/site-header';


/* -------------------------------------------------------------------------
 Navigation
 --------------------------------------------------------------------------- */

@import 'components/navigation/meta-navigation';
@import 'components/navigation/navigation';
@import 'components/navigation/offcanvas/offcanvas-wrappers';
@import 'components/navigation/offcanvas/offcanvas';


/* -------------------------------------------------------------------------
 Form
 --------------------------------------------------------------------------- */

@import 'components/form/form';
@import 'components/form/form-checkbox';
@import 'components/form/form-select';
@import 'components/form/form-validation';


/* -------------------------------------------------------------------------
 Lightbox
 --------------------------------------------------------------------------- */

//@import 'components/lightbox/lightbox';
//@import 'components/lightbox/lightbox-closebutton';


/* -------------------------------------------------------------------------
 Components
 --------------------------------------------------------------------------- */

 /* styles for every project */
 @import "components/su/_base";
 @import "components/su/_grid";
 @import "components/su/_typography";
 //@import "components/su/_header";
 @import "components/su/_nav";
 @import "components/su/_footer";

 /* optional styles */
 @import "components/su/_offcanvas";
 @import "components/su/_contentelements";
 @import "components/su/_button-links";
 @import "components/su/_ratiobox";
 @import "components/su/_form";
 @import "components/su/_slider";
 @import "components/su/_video";


 /* styles for every project */
 @import "components/su/_responsive";


/* -------------------------------------------------------------------------
 Content
 --------------------------------------------------------------------------- */
/*
// homepage
@import 'components/content/homepage/homepage-teaser';
@import 'components/content/homepage/homepage-teaser_fullscreen';
@import 'components/content/homepage/homepage-teaser_flex';
//@import 'components/content/homepage/homepage-teaser_grid';
@import 'components/content/homepage/homepage-teaser_effects';

// page header
@import 'components/content/page-header';

// accordeon
@import 'components/content/accordeon';

// adress
@import 'components/content/adress';

// c-text
@import 'components/content/c-text';

// image
@import 'components/content/image-only';
@import 'components/content/image-text';

// infografik
@import 'components/content/infografik';

// jobs
@import 'components/content/jobs';

// map
@import 'components/content/map';

// mitarbeiter
@import 'components/content/mitarbeiter';

// mitgliedschaften
@import 'components/content/mitgliedschaften';

// news detail
@import 'components/content/newsdetail';

// news übersicht
@import 'components/content/newsuebersicht';

// quote
@import 'components/content/quote';
*/

// section
@import 'components/content/section';

/*
// title
@import 'components/content/title';
*/

/* -------------------------------------------------------------------------
 Teaser
 --------------------------------------------------------------------------- */

@import 'components/teaser/news-teaser.scss';
@import 'components/teaser/related-pages-teaser.scss';


/* -------------------------------------------------------------------------
 Sitefooter
 --------------------------------------------------------------------------- */

//@import 'components/site-footer';


/* -------------------------------------------------------------------------
Cookie Notice
--------------------------------------------------------------------------- */

@import 'components/cookie-notice';


/* -------------------------------------------------------------------------
 Fix offcanvas navigation
 --------------------------------------------------------------------------- */

@import 'components/navigation/offcanvas/opened/fix-elements';


/* ==========================================================================
   DEV Info: Position of the breakpoints info
   ========================================================================== */

/*body:before{
	bottom: 0;
	top: auto;
	z-index: 3000;
}*/
